import { PropsWithChildren, createContext, useState, useContext } from 'react';

import { PagesInJourney } from '@/components/layouts/HomeMoveLayout/index.types';
import { NewAddressType } from '@/types/moveHome';

import { HomeMoveContextReturnValue } from './index.types';

export const HomeMoveContext = createContext({} as HomeMoveContextReturnValue);

export const HomeMoveOutContextProvider = ({ children }: PropsWithChildren) => {
  const [pagesInJourney, setPagesInJourney] = useState<PagesInJourney>([]);
  const [isMetered, setIsMetered] = useState<boolean>(false);
  const [moveOutDate, setMoveOutDate] = useState<Date>();
  const [moveInDate, setMoveInDate] = useState<Date>();
  const [finalReading, setFinalReading] = useState<string>();
  const [newBillingAddress, setNewBillingAddress] = useState<NewAddressType>();
  const [hasNewAddress, setHasNewAddress] = useState<string | undefined>();
  const [isResponsibleForBill, setIsResponsibleForBill] = useState<
    string | undefined
  >();

  /* Setting postcode from the frontend input because the BE 
    doesn't seem to return one */
  const [postcode, setPostcode] = useState<string | undefined>();

  return (
    <HomeMoveContext.Provider
      value={{
        isMetered,
        setIsMetered,
        moveOutDate,
        setMoveOutDate,
        moveInDate,
        setMoveInDate,
        finalReading,
        setFinalReading,
        pagesInJourney,
        setPagesInJourney,
        setNewBillingAddress,
        newBillingAddress,
        setPostcode,
        postcode,
        hasNewAddress,
        setHasNewAddress,
        isResponsibleForBill,
        setIsResponsibleForBill,
      }}
    >
      {children}
    </HomeMoveContext.Provider>
  );
};

export const useHomeMoveContext = () => {
  const context = useContext(HomeMoveContext);
  if (context === undefined) {
    throw new Error(
      'useHomeMoveContext must be used within a HomeMoveContextProvider'
    );
  }
  return context;
};
