import NextImage from 'next/image';
import Link from 'next/link';
import { Stack } from '@krakentech/coral';
import { Header as OctoHeader } from '@krakentech/coral-organisms';

import { useAuth } from '@/components/Providers/Auth';
import { DesktopAndTabletOnlyDiv, MobileOnlyDiv } from '@/styles/Utils.styled';
import { INTERNAL_PATHS } from '@/utils/urls';

import LeepLogo from '../../public/images/logos/leep.png';
import NautilusLogo from '../../public/images/logos/nautilus.svg';
import PwlLogo from '../../public/images/logos/pwl.png';
import StwLogo from '../../public/images/logos/stw.png';
import LogoutButton from '../auth/LogoutButton';
import ContactUsButton from '../ContactUsButton';

import { StyledHeaderContainer, StyledLogoLink } from './index.styled';
import { LogoConfig } from './index.types';

const Header = () => {
  const { isAuthenticated } = useAuth();

  const routes = {
    dashboard: isAuthenticated
      ? INTERNAL_PATHS.HOME.path
      : INTERNAL_PATHS.LOGIN.path,
    contactUs: process.env.NEXT_PUBLIC_HELP_LINK,
  };

  const logos: Record<string, LogoConfig> = {
    nautilus: {
      src: NautilusLogo,
      desktop: {
        width: 240,
        height: 54,
      },
      mobile: {
        width: 124,
        height: 44,
      },
    },
    pwl: {
      src: PwlLogo,
      desktop: {
        width: 240,
        height: 54,
      },
      mobile: {
        width: 125,
        height: 28,
      },
    },
    leep: {
      src: LeepLogo,
      desktop: {
        width: 54,
        height: 54,
      },
      mobile: {
        width: 44,
        height: 44,
      },
    },
    stw: {
      src: StwLogo,
      desktop: {
        width: 90,
        height: 54,
      },
      mobile: {
        width: 75,
        height: 44,
      },
    },
  };

  const brand = process.env.NEXT_PUBLIC_THEME as string;
  const logoAlt = process.env.NEXT_PUBLIC_COMPANY_NAME;

  return (
    <StyledHeaderContainer>
      <DesktopAndTabletOnlyDiv>
        <OctoHeader>
          <Stack justifyContent="space-between" fullWidth>
            <Link href={routes.dashboard} passHref>
              <StyledLogoLink>
                <Stack alignItems="center">
                  <NextImage
                    src={logos[brand].src}
                    alt={logoAlt}
                    width={logos[brand].desktop.width}
                    height={logos[brand].desktop.height}
                  />
                </Stack>
              </StyledLogoLink>
            </Link>

            <Stack alignItems="center">
              <ContactUsButton />
              {isAuthenticated && <LogoutButton isMobile={false} />}
            </Stack>
          </Stack>
        </OctoHeader>
      </DesktopAndTabletOnlyDiv>

      <MobileOnlyDiv>
        <OctoHeader>
          <Stack justifyContent="space-between" alignItems="center" fullWidth>
            <Link href={routes.dashboard} passHref>
              <StyledLogoLink>
                <Stack alignItems="center">
                  <NextImage
                    src={logos[brand].src}
                    alt={logoAlt}
                    width={logos[brand].mobile.width}
                    height={logos[brand].mobile.height}
                  />
                </Stack>
              </StyledLogoLink>
            </Link>

            <Stack gap="sm">
              <ContactUsButton />
              {isAuthenticated && <LogoutButton isMobile />}
            </Stack>
          </Stack>
        </OctoHeader>
      </MobileOnlyDiv>
    </StyledHeaderContainer>
  );
};

export default Header;
