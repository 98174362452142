import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import HasActiveDunningProcess from '@/hooks/accounts/useHasActiveDunningProcess/HasActiveDunningProcess.graphql';
import { hasActiveDunningProcessSchema } from '@/hooks/accounts/validations';

type DunningDataType = {
  account: {
    hasActiveDunningProcess: boolean;
  };
};

export const useHasActiveDunningProcess = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['hasActiveDunningProcess'],
    query: HasActiveDunningProcess,
    select: (data: DunningDataType) => data.account.hasActiveDunningProcess,
    validateFn(response) {
      return hasActiveDunningProcessSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber },
  });
};
