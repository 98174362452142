import { Stack } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { useFormikContext } from 'formik';

import { addHyphensIntoSortCode } from '@/utils/formatters/sortCode';

import MutedText from '../../MutedText';

const DirectDebitForm = () => {
  const { setFieldValue } = useFormikContext();
  const hyphenateValue = (value: string) => value + '-';
  const branchCode = 'branchCode';

  const hyphenateSortCodeOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;

    /* Add the first hyphen after 2 characters, so we have 3 total characters.
        Then add the second hyphen after 2 more characters, i.e. when length is 5. */
    if (value.length === 2 || value.length === 5) {
      const hyphenatedValue = hyphenateValue(value);
      setFieldValue(branchCode, hyphenatedValue);
    }
  };

  const hyphenateSortCodeOnBlur = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;

    const hyphenatedValue = addHyphensIntoSortCode(value);
    setFieldValue(branchCode, hyphenatedValue);
  };

  return (
    <>
      <FormikTextField label="Account holder's name" name="accountHolder" />
      <Stack direction="vertical" gap="xs">
        <FormikTextField
          label="Account number"
          name="accountNumber"
          inputProps={{ inputMode: 'numeric', maxLength: 8 }}
          type="text"
        />
        <MutedText>Please enter 8 digits</MutedText>
      </Stack>
      <Stack direction="vertical" gap="xs">
        <FormikTextField
          label="Sort code"
          name="branchCode"
          inputProps={{ inputMode: 'numeric', maxLength: 8 }}
          type="text"
          onChange={hyphenateSortCodeOnChange}
          onBlur={hyphenateSortCodeOnBlur}
        />
        <MutedText>
          Please enter the 6 digits - no hyphens or spaces are needed
        </MutedText>
      </Stack>
    </>
  );
};

export default DirectDebitForm;
