import { Grid } from '@krakentech/coral';
import { IconHome } from '@krakentech/icons';

import { ActionCard } from '@/components';
import DunningDialog from '@/components/DunningDialog';
import useDunningDialog from '@/hooks/utils/useDunningDialog';
import { INTERNAL_PATHS } from '@/utils/urls';

const HomeMoveCard = () => {
  const {
    showDunningDialog,
    setShowDunningDialog,
    hasActiveDunning,
    isDunningLoading,
    isDunningError,
  } = useDunningDialog();

  const actionCardHref = INTERNAL_PATHS.MOVE_HOME[0].path;

  return (
    <>
      <Grid.Item>
        <ActionCard
          {...(!hasActiveDunning && { href: actionCardHref })}
          title="Move out of home"
          titleComponent="p"
          icon={<IconHome size={32} />}
          onClick={setShowDunningDialog}
          isLoading={isDunningLoading}
          isError={isDunningError}
        />
      </Grid.Item>
      <DunningDialog
        isOpen={showDunningDialog}
        closeDialog={setShowDunningDialog}
        ariaLabelAction="moveOutOfHome"
      />
    </>
  );
};

export default HomeMoveCard;
