import { Dialog, Stack, Typography, Button, Link } from '@krakentech/coral';

export type DunningDialogTypeProps = {
  isOpen: boolean;
  closeDialog: () => void;
  title?: string;
  ariaLabelAction: string;
};

const DunningDialog = ({
  isOpen,
  closeDialog,
  title = "Sorry, you can't complete this online",
  ariaLabelAction,
}: DunningDialogTypeProps) => {
  return (
    <Dialog
      open={isOpen}
      ariaLabelledBy={`${ariaLabelAction}ActionCard`}
      onClose={closeDialog}
    >
      <Stack direction="vertical" gap="lg">
        <Stack direction="vertical" gap="xsSm">
          <Typography variant="h2" component="h1">
            {title}
          </Typography>
          <Typography>
            Access to this function is currently restricted online due to
            overdue payments on your account. Please{' '}
            <Link href={process.env.NEXT_PUBLIC_HELP_LINK}>get in touch</Link>{' '}
            so we can help you.
          </Typography>
        </Stack>
        <Button onClick={closeDialog}>Close</Button>
      </Stack>
    </Dialog>
  );
};

export default DunningDialog;
