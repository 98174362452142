// Some skeletons with pre-set widths and heights which we can use in place of components they are foreshadowing
// Also, https://www.youtube.com/watch?v=g_HSRHqd_7s
import { Skeleton, Stack } from '@krakentech/coral';

const H1Skeleton = ({ width = 300 }: { width?: number }) => (
  <Skeleton variant="rounded" width={width} height={40} />
);

const H2Skeleton = ({ width = 250 }: { width?: number }) => (
  <Skeleton variant="rounded" width={width} height={22} />
);

const H3Skeleton = ({ width = 250 }: { width?: number }) => (
  <Skeleton variant="rounded" width={width} height={20} />
);

const H4Skeleton = ({ width = 250 }: { width?: number }) => (
  <Skeleton variant="rounded" width={width} height={22} />
);

const H5Skeleton = ({ width = 250 }: { width?: number }) => (
  <Skeleton variant="rounded" width={width} height={18} />
);

const Body1Skeleton = ({ width }: { width?: number }) => (
  <Skeleton variant="text" width={width} height={18} />
);

const Body2Skeleton = ({ width }: { width?: number }) => (
  <Skeleton variant="text" width={width} height={16} />
);

const SmallButtonSkeleton = () => (
  <Skeleton variant="rounded" height={54} width={150} />
);

const FullWidthButtonSkeleton = () => (
  <Skeleton variant="rounded" height={54} />
);

const TextFieldSkeleton = () => <Skeleton variant="rounded" height={60} />;

const CircularSkeleton = ({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) => <Skeleton variant="circular" width={width} height={height} />;

const SwitchWithLabelSkeleton = () => (
  <Stack justifyContent="space-between" fullWidth gap="sm">
    <Skeleton variant="text" width={200} height={18} />
    <Skeleton variant="text" width={40} />
  </Stack>
);

const RadioCardSkeleton = () => <Skeleton variant="rounded" height={88} />;

const CheckboxSkeleton = () => (
  <Stack>
    <Skeleton variant="rounded" height={18} width={18} />
    <H5Skeleton />
  </Stack>
);

const AlertSkeleton = () => <Skeleton variant="rounded" height={50} />;

export {
  H1Skeleton,
  H2Skeleton,
  H3Skeleton,
  H4Skeleton,
  H5Skeleton,
  Body1Skeleton,
  Body2Skeleton,
  SmallButtonSkeleton,
  TextFieldSkeleton,
  FullWidthButtonSkeleton,
  CircularSkeleton,
  SwitchWithLabelSkeleton,
  RadioCardSkeleton,
  CheckboxSkeleton,
  AlertSkeleton,
};
