import {
  Card,
  Stack,
  Typography,
  useCoralBreakpoints,
  Button,
} from '@krakentech/coral';

import { SummaryCardItem } from '@/types/directDebit';

import BoxWithGreyBorder from '../BoxWithGreyBorder';
import MutedText from '../MutedText';
import { Body1Skeleton, H2Skeleton } from '../Skeletons';

const SummaryCard = ({
  title = 'Summary',
  items,
  isLoading = false,
}: {
  title?: string;
  items: Array<SummaryCardItem>;
  isLoading?: boolean;
}) => {
  const { isMinMd } = useCoralBreakpoints();

  if (isLoading) {
    return (
      <Card>
        <Stack direction="vertical" gap={isMinMd ? 'md' : 'sm'}>
          <H2Skeleton />

          <BoxWithGreyBorder>
            <Stack
              direction="vertical"
              gap="xs"
              alignItems="center"
              md={{
                direction: 'vertical',
                gap: 'xs',
                alignItems: 'flex-start',
              }}
            >
              <Body1Skeleton width={isMinMd ? 200 : undefined} />
              <Body1Skeleton width={isMinMd ? 200 : undefined} />
            </Stack>
          </BoxWithGreyBorder>
        </Stack>
      </Card>
    );
  }

  return (
    <Card>
      <Stack direction="vertical" gap={isMinMd ? 'md' : 'sm'}>
        <Typography variant="h2">{title}</Typography>

        {items.map((item, i) => (
          <BoxWithGreyBorder key={i}>
            <Stack
              direction="vertical"
              gap="xs"
              alignItems="center"
              md={{
                direction: 'vertical',
                gap: 'xs',
                alignItems: 'flex-start',
              }}
            >
              <Stack
                alignItems="center"
                fullWidth
                direction="vertical"
                md={{
                  justifyContent: 'space-between',
                  direction: 'horizontal',
                }}
              >
                <Stack direction="vertical" gap="xs">
                  <MutedText
                    variant="h3"
                    textAlign={isMinMd ? 'left' : 'center'}
                  >
                    {item.title}
                  </MutedText>

                  <Typography textAlign={isMinMd ? 'left' : 'center'}>
                    {item.content}
                  </Typography>
                </Stack>

                {item.showChangeButton && (
                  <Button
                    variant="outlined"
                    onClick={item.onChangeButtonClick}
                    fullWidth={!isMinMd}
                  >
                    Change
                  </Button>
                )}
              </Stack>
            </Stack>
          </BoxWithGreyBorder>
        ))}
      </Stack>
    </Card>
  );
};

export default SummaryCard;
