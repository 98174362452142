import { Stack, Typography, useCoralBreakpoints } from '@krakentech/coral';

import { ConditionallyWrapButtonInNextLink } from '@/components';
import DunningDialog from '@/components/DunningDialog';
import useDunningDialog from '@/hooks/utils/useDunningDialog';
import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { INTERNAL_PATHS } from '@/utils/urls';

const SetUpDirectDebitCta = () => {
  const { isMinMd } = useCoralBreakpoints();
  const showDirectDebitV2 = useFeatureFlag(FeatureNames.DirectDebitVersion2);

  const {
    showDunningDialog,
    setShowDunningDialog,
    hasActiveDunning,
    isDunningLoading,
  } = useDunningDialog();

  const setUpDirectDebitPage = showDirectDebitV2
    ? INTERNAL_PATHS.SET_UP_DIRECT_DEBIT[0]
    : INTERNAL_PATHS.SET_UP_DIRECT_DEBIT[1];

  return (
    <Stack direction="vertical" gap="md">
      <Typography textAlign={isMinMd ? 'left' : 'center'}>
        Change to Direct Debit to make your payments easier to manage - it only
        takes a minute!
      </Typography>

      <ConditionallyWrapButtonInNextLink
        condition={hasActiveDunning as boolean}
        href={setUpDirectDebitPage.path}
        buttonAction={setShowDunningDialog}
        buttonText="Start"
        loading={isDunningLoading}
      />

      <DunningDialog
        isOpen={showDunningDialog}
        closeDialog={setShowDunningDialog}
        ariaLabelAction="setupDirectDebit"
      />
    </Stack>
  );
};

export default SetUpDirectDebitCta;
