import { useState, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import { useAuth } from '@/components/Providers/Auth';
import { trackPageViewForGoogle } from '@/lib/gtag';
import { FeatureNames } from '@/types/features';
import { HOSTNAME } from '@/utils/environment';

import { useFeatureFlag } from '../useFeatureFlags';

export const useAnalytics = () => {
  const [dataLayer, setDataLayer] = useState({});
  const [pageCategories, setPageCategories] = useState<string[]>([]);
  const [adobeDataLayerReady, setAdobeDataLayerReady] = useState(false);
  const { pathname, isReady, asPath } = useRouter();
  const { isAuthenticated } = useAuth();
  const adobeAnalyticsEnabled = useFeatureFlag(FeatureNames.AdobeAnalytics);

  const formatTitle = (title: string) => {
    return title.replace(' - ', ':').replace(' | ', ':').toLowerCase();
  };

  const getPageName = useCallback(() => {
    let formattedTitle = '';

    // If document.title is present, use that
    if (document.title) {
      formattedTitle = formatTitle(document.title);
    } else {
      // If we don't have a document.title, let's just display the company name for now. Also go away Typescript, NEXT_PUBLIC_COMPANY_NAME is always defined
      formattedTitle = process.env.NEXT_PUBLIC_COMPANY_NAME
        ? process.env.NEXT_PUBLIC_COMPANY_NAME
        : '';
    }

    const name = `${pageCategories[0]}:${formattedTitle}`;
    return name;
  }, [pageCategories]);

  const setAdobeDataLayer = useCallback(() => {
    if (typeof document === 'undefined' || !isReady) {
      return;
    }

    const pageName = getPageName();

    setDataLayer({
      page: {
        name: pageName,
        uri: pathname,
        params: '',
        domain: HOSTNAME,
        type: 'content-page', // TODO: figure out how to get this on error pages
        primary_category:
          pageCategories && pageCategories[0] ? pageCategories[0] : '',
        sub_category1:
          pageCategories && pageCategories[1] ? pageCategories[1] : '',
        sub_category2:
          pageCategories && pageCategories[2] ? pageCategories[2] : '', // Right now we're only going to 2 levels deep right now but this is here as some future-proofing
        sub_category3: '',
      },
      user: {
        logged_in_status: isAuthenticated ? 'logged in' : 'not logged in',
      },
      version: '0.0.2',
    });
  }, [pathname, isReady, isAuthenticated, pageCategories, getPageName]);

  const trackPageView = useCallback(() => {
    trackPageViewForGoogle({ url: asPath });

    if (adobeAnalyticsEnabled) {
      setAdobeDataLayer();
      setAdobeDataLayerReady(true);
    }
  }, [adobeAnalyticsEnabled, setAdobeDataLayer, asPath]);

  useEffect(() => {
    let categories = [];

    // Handle the dashboard first. If the path is just "/" then we know we're on the dashboard
    if (pathname === '/') {
      categories.push('dashboard');
      setPageCategories(categories);
      return;
    }

    // Split this by "/" and add to categories array
    categories.push(
      ...pathname.split('/').filter((category) => category !== '')
    );

    // Remove from categories array any strings with [  and ] in them, as well as any category that's just "accounts"
    categories = categories.filter(
      (category) =>
        !category.includes('[') &&
        !category.includes(']') &&
        !category.includes('accounts')
    );

    setPageCategories(categories);
  }, [pathname]);

  useEffect(() => {
    if (typeof window !== 'undefined' && adobeAnalyticsEnabled) {
      window.digitalData = dataLayer;
    }
  });

  return {
    trackPageView,
    adobe: {
      enabled: adobeAnalyticsEnabled,
      ready: adobeAnalyticsEnabled && adobeDataLayerReady,
    },
  };
};
