// Normally we can use the useFeatureFlags hook instead and that is definitely preferred because it's cool and nice. This is only here for when we can't use a hook to get the feature flag, e.g. in util and lib functions like these
export const showAdobeAnalytics =
  process.env.NEXT_PUBLIC_FF_ADOBE_ANALYTICS === '1';

const getAnalyticsOptInCategory = (): string => {
  if (!window.adobe) {
    return '';
  }

  return window.adobe.OptInCategories['ANALYTICS'];
};

export const optInToAdobeAnalytics = (): void => {
  if (!window.adobe) {
    return;
  }

  const ADOBE_ANALYTICS_OPT_IN_CATEGORY = getAnalyticsOptInCategory();

  window.adobe.optIn.approve(ADOBE_ANALYTICS_OPT_IN_CATEGORY);
  window.adobe.optIn.complete();
};

export const optOutOfAdobeAnalytics = (): void => {
  if (!window.adobe) {
    return;
  }

  const ADOBE_ANALYTICS_OPT_IN_CATEGORY = getAnalyticsOptInCategory();

  window.adobe.optIn.deny(ADOBE_ANALYTICS_OPT_IN_CATEGORY);
  window.adobe.optIn.complete();
};

export const setAdobeAnalyticsConsent = (
  hasConsentedToAnalyticsStorage: boolean
): void => {
  if (showAdobeAnalytics) {
    if (hasConsentedToAnalyticsStorage) {
      optInToAdobeAnalytics();
    } else {
      optOutOfAdobeAnalytics();
    }
  }
};
