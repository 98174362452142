import Link from 'next/link';
import { Button } from '@krakentech/coral';

import { ConditionallyWrapButtonProps } from './index.types';

// Sometimes if the href is undefined we want to be able to trigger
// an action from the button. But Next Links wont accept undefined as
// href value. This component conditionally wraps the button in a Link
// if the href is defined or returns the button if the href is not defined

const ConditionallyWrapButtonInNexLink = ({
  condition,
  href,
  buttonAction,
  buttonText,
  loading = false,
}: ConditionallyWrapButtonProps) => {
  return condition ? (
    <Button size="medium" onClick={buttonAction} loading={loading}>
      {buttonText}
    </Button>
  ) : (
    <Link href={href} passHref>
      <Button size="medium">{buttonText}</Button>
    </Link>
  );
};

export default ConditionallyWrapButtonInNexLink;
