import { setCookie } from 'cookies-next';
import { addYears, addMonths } from 'date-fns';

import { setAdobeAnalyticsConsent } from '@/lib/adobe';
import { setGoogleAnalyticsConsent } from '@/lib/gtag';

const preferences = [
  'essential',
  'analytics',
  'marketing',
  'everything',
] as const;
export type CookiePreferenceType = (typeof preferences)[number];

export const COOKIES = {
  SNOOZE_ACCOUNT_ENRICHMENT: 'SNOOZE_ACCOUNT_ENRICHMENT',
  DISMISS_COOKIE_BANNER: 'dismissCookieBanner',
};

// Copied from OEIT

/**
 * Return a valid CookiePreference from a JSON-encoded string or throw.
 */
const parseCookiePreference = (
  localStorageValue: string
): CookiePreferenceType => {
  const preference = preferences.find(
    (validName) => validName === localStorageValue
  );
  if (preference) {
    // `preference` comes from the list of `preferences` so the compiler is happy.
    return preference;
  }
  throw new Error(`${preference} is not a cookie preference.`);
};

const setCookieBannerCookie = (hasOptedOutOfSomeTracking: boolean): void => {
  const today = new Date();
  let expires = addYears(today, 1);
  if (hasOptedOutOfSomeTracking) {
    expires = addMonths(today, 6);
  }
  setCookie(COOKIES.DISMISS_COOKIE_BANNER, 'true', { expires: expires });
};

// TODO: have some sort of error handling for in case something goes wrong with opting in to cookies. Probably need a Toast to pop up. This may require a hook called useCookie or something
export const updateCookiePreference = (
  preference: CookiePreferenceType
): void => {
  const hasConsentedToMarketingStorage = hasMarketingConsent(preference);
  const hasConsentedToAnalyticsStorage = hasAnalyticsConsent(preference);

  // Opt in/out of Google Analytics and Adobe Analytics
  updateConsent(hasConsentedToMarketingStorage, hasConsentedToAnalyticsStorage);

  // TODO: See if we can get rid of this local storage item and just use the cookie instead, because it's silly having this saved in two places
  // Save user's cookie preference to local storage and an actual cookie
  localStorage.setItem('cookiePreference', preference);

  setCookieBannerCookie(preference !== 'everything');
};

export const getSavedUserPreference = (): CookiePreferenceType | null => {
  /* checked we're not on the server */
  if (typeof window == 'undefined') {
    return null;
  }
  const preference = localStorage.getItem('cookiePreference');
  // we may not have a stored preference, which is fine, we'll show the cookie banner
  if (!preference) {
    return null;
  }
  return parseCookiePreference(preference);
};

export const hasMarketingConsent = (
  preference: CookiePreferenceType
): boolean => {
  // whether the passed consent allows for marketing tracking
  return preference === 'marketing' || preference === 'everything';
};

export const hasAnalyticsConsent = (
  preference: CookiePreferenceType
): boolean => {
  // whether the passed consent allows for analytics tracking
  return preference === 'analytics' || preference === 'everything';
};

export const updateConsent = (
  hasConsentedToMarketingStorage: boolean,
  hasConsentedToAnalyticsStorage: boolean
): void => {
  setGoogleAnalyticsConsent(
    hasConsentedToMarketingStorage,
    hasConsentedToAnalyticsStorage
  );
  setAdobeAnalyticsConsent(hasConsentedToAnalyticsStorage);
};
