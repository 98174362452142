import { Grid, Typography } from '@krakentech/coral';

import { Body1Skeleton, PageError, PaymentScheduleCard } from '@/components';
import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';
import { usePaymentForecast } from '@/hooks/billsAndPayments/usePaymentForecast';
import { PaymentSchedule } from '@/types/paymentSchedules';
import { formatCurrency } from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';
import { getPaymentFrequency } from '@/utils/paymentSchedule';

const NextAndFollowingPaymentsCards = ({
  currentActivePaymentScheduleData,
}: {
  currentActivePaymentScheduleData: { node: PaymentSchedule };
}) => {
  const {
    paymentAmount,
    paymentDay,
    paymentFrequency,
    paymentFrequencyMultiplier,
    validTo,
  } = currentActivePaymentScheduleData?.node || {};
  const { isLoading, isError, data } = usePaymentForecast({
    first: 2,
  });

  if (isLoading) return <LoadingState />;

  if (isError) return <PageError />;

  const { MONTHLY } = PAYMENT_FREQUENCY;

  const hasNoForecastedPayments = data.length === 0;
  const hasNextAndFollowingForecastedPayments = data.length > 1;

  if (hasNoForecastedPayments)
    return (
      <Typography>
        Please wait until your next bill to see your charges. A few days after
        this is issued, we&apos;ll collect your balance in full
      </Typography>
    );

  const getNextPaymentText = () => (
    <>
      is due to be collected on the{' '}
      <strong>{formatFullDateShortMonth(new Date(data[0].node.date))}</strong>
    </>
  );

  const getFollowingPaymentsText = () => {
    const frequency =
      paymentFrequency === MONTHLY
        ? `on the ${cardinalNumberToOrdinalNumber(paymentDay)} of`
        : '';

    const untilEndDate = validTo ? (
      <Typography>
        {' '}
        until <strong>{formatFullDateShortMonth(new Date(validTo))}</strong>
      </Typography>
    ) : (
      ''
    );

    return (
      <>
        will be collected {frequency}{' '}
        <strong>
          every{' '}
          {getPaymentFrequency(paymentFrequency, paymentFrequencyMultiplier)}
        </strong>
        {untilEndDate}
      </>
    );
  };

  return (
    <Grid
      gap="sm"
      templateColumns={`repeat(1, 1fr)`}
      md={{
        gap: 'md',
        templateColumns: 'repeat(${numberOfDesktopGridColumns}, 1fr)',
      }}
    >
      <PaymentScheduleCard title="Next payment">
        <Typography textAlign="center" variant="homepageTitle" component="p">
          {formatCurrency(paymentAmount)}
        </Typography>
        <Typography
          textAlign="center"
          dataAttributes={{ 'data-testid': 'nextPaymentText' }}
        >
          {getNextPaymentText()}
        </Typography>
      </PaymentScheduleCard>
      {hasNextAndFollowingForecastedPayments && (
        <PaymentScheduleCard title="Following payments">
          <Typography textAlign="center" variant="homepageTitle" component="p">
            {formatCurrency(paymentAmount)}
          </Typography>
          <Typography
            textAlign="center"
            dataAttributes={{
              'data-testid': 'followingPaymentsText',
            }}
          >
            {getFollowingPaymentsText()}
          </Typography>
        </PaymentScheduleCard>
      )}
    </Grid>
  );
};

const LoadingState = () => (
  <>
    <Body1Skeleton />
    <Body1Skeleton />
  </>
);

export default NextAndFollowingPaymentsCards;
