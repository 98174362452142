import {
  Button,
  Card,
  Link,
  Stack,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';
import { IconHome } from '@krakentech/icons';

import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useMeterDetails } from '@/hooks/usage/useMeterDetails';
import { formatFullDateShortMonth } from '@/utils/formatters/date';
import { INTERNAL_PATHS } from '@/utils/urls';

import { StyledMoveOutCard, StyledSubmitReadingText } from './index.styled';
import { MoveOutCardProps } from './index.types';

const MoveOutCard = ({ movingOutDate }: MoveOutCardProps) => {
  const { accountNumber } = useAccountNumberContext();

  const { data, isLoading, isError } = useMeterDetails({ accountNumber });
  const { isMinMd } = useCoralBreakpoints();

  if (!data || isLoading || isError) {
    return null;
  }

  const properties = data?.properties || [];
  const activeWaterMeters = properties[0]?.activeWaterMeters || [];

  const hasNoActiveProperties = properties.length === 0;
  const hasNoActiveWaterMeters = activeWaterMeters.length === 0;

  const heading = hasNoActiveProperties
    ? 'You have moved out'
    : "You're moving out!";

  const description = hasNoActiveProperties
    ? 'You are no longer responsible for this property. However you can still access past payments, bills and settings (please note: some settings are now disabled).'
    : hasNoActiveWaterMeters
      ? ''
      : `If you haven't already, please submit a reading on ${
          movingOutDate ? formatFullDateShortMonth(movingOutDate) : ''
        }.`;

  const submitReadingHref = INTERNAL_PATHS.SUBMIT_METER_READING.createPath({
    meterId: activeWaterMeters[0]?.id,
  });

  const layout = isMinMd ? 'horizontal' : 'vertical';
  const textAlign = isMinMd ? 'left' : 'center';

  return (
    <StyledMoveOutCard>
      <Card>
        <Stack direction={layout} alignItems="center" gap="smMd">
          <IconHome size={72} />
          <Stack direction="vertical" gap="xs">
            <Typography variant="h2" textAlign={textAlign}>
              {heading}
            </Typography>
            <Typography textAlign={textAlign} textWrap="pretty">
              {description} If you haven&apos;t informed us of this move, please{' '}
              <Link href={process.env.NEXT_PUBLIC_HELP_LINK} target="_blank">
                contact us
              </Link>
            </Typography>
          </Stack>
          {!hasNoActiveProperties && !hasNoActiveWaterMeters && (
            <Button href={submitReadingHref} fullWidth={!isMinMd}>
              <StyledSubmitReadingText>Submit reading</StyledSubmitReadingText>
            </Button>
          )}
        </Stack>
      </Card>
    </StyledMoveOutCard>
  );
};

export default MoveOutCard;
