import { useEffect, useState } from 'react';
import {
  Alert,
  Typography,
  Stack,
  Card,
  Button,
  Link,
  useCoralTheme,
  Visibility,
} from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { IconSearch, IconWarning } from '@krakentech/icons';
import { useFormikContext } from 'formik';

import { FormFieldWithButton, Select } from '@/components';
import { AddressType } from '@/context/CreateNewAccountContext/index.types';
import {
  FoundAddress,
  FoundBillingAddress,
  NewAddressType,
} from '@/types/moveHome';
import { sortAddressList } from '@/utils/sortAddressList';

import { AddressFinderProps } from './index.types';

const AddressFinder = ({
  postcode,
  defaultSelectedAddress,
  handleAddressChange,
  query,
  title = 'Enter your new address',
}: AddressFinderProps) => {
  const { setFieldValue, errors, values } = useFormikContext<{
    postcode: string;
    address?: AddressType;
  }>();

  const postcodeError = errors?.postcode;
  const postcodeValue = values?.postcode;

  const {
    color: {
      action,
      base: { main: buttonIconColour },
    },
  } = useCoralTheme();

  const [foundAddresses, setFoundAddresses] = useState<
    FoundAddress[] | FoundBillingAddress[] | null
  >(null);

  const {
    data: addressData,
    isError: isAddressDataError,
    refetch: refetchAddressData,
    isFetching: fetchingAddressData,
  } = query({ postcode });

  const addressDataValues =
    /* 
            This takes the response from the api and shapes the data correctly
            for the Coral Select component requirement for options
        */
    foundAddresses?.map(({ node }) => {
      if (!node) return;

      /* 
                This handles the scenario where the backend provides just a single string 
                instead of an object which will include address, propertyId and metered properties
            */
      if (typeof node === 'string') {
        return {
          value: node,
          label: node,
        };
      }

      const { address, propertyId, metered } = node;

      return {
        value: address,
        label: address,
        ...(propertyId && { propertyId }),
        ...(metered && { metered }),
      };
    }) || [];

  const sortedAddressList = sortAddressList(
    addressDataValues as NewAddressType[]
  );

  const [isPostcodeUnavailable, setIsPostcodeUnavailable] = useState(false);
  const postcodeIsInSavedAddress = defaultSelectedAddress?.value
    .toString()
    .includes(postcodeValue);

  const hasAddressData = !!foundAddresses?.length;

  const handleFindAddresses = () => {
    refetchAddressData();
  };

  const handleOnKeyDown = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleFindAddresses();
    }
  };

  useEffect(() => {
    if (isAddressDataError) {
      setFoundAddresses([]);
      setIsPostcodeUnavailable(true);
      setFieldValue('address', {});
      return;
    }
    if (fetchingAddressData) {
      setFoundAddresses(null);
      return;
    }
    if (addressData) {
      setFoundAddresses(addressData);
    }
  }, [
    isAddressDataError,
    addressData,
    fetchingAddressData,
    setFoundAddresses,
    setFieldValue,
  ]);

  useEffect(() => {
    if (!postcodeValue || postcodeError || !postcodeIsInSavedAddress) {
      // Resets the value if a user searches for another
      setFoundAddresses(null);
      setFieldValue('address', {});
    }
  }, [
    postcodeValue,
    postcodeError,
    setFieldValue,
    setFoundAddresses,
    postcodeIsInSavedAddress,
  ]);

  useEffect(() => setIsPostcodeUnavailable(false), [postcodeValue]);

  return (
    <Card>
      <Stack direction="vertical" gap="smMd">
        <Typography variant="h2">{title}</Typography>
        <FormFieldWithButton
          input={
            <FormikTextField
              name="postcode"
              label="Enter postcode"
              onChange={(event) => {
                const { value } = event.target;

                setFieldValue('postcode', value.toUpperCase().trim());
              }}
            />
          }
          button={
            <Button
              onClick={handleFindAddresses}
              onKeyDown={handleOnKeyDown}
              color="primary"
              size="large"
              aria-label="Find address"
              fullWidth
              md={{
                size: 'medium',
              }}
              startIcon={<IconSearch color={buttonIconColour} size={16} />}
              disabled={
                fetchingAddressData || !!postcodeError || isPostcodeUnavailable
              }
              loading={fetchingAddressData}
              loadingLabel={
                <Visibility display="none" md={{ display: 'block' }}>
                  Loading...
                </Visibility>
              }
            >
              <Visibility display="none" md={{ display: 'block' }}>
                Find address
              </Visibility>
            </Button>
          }
          mutedText={
            <>
              Please only enter a UK postcode. If you can&apos;t find your
              address then please{' '}
              <Link href={process.env.NEXT_PUBLIC_HELP_LINK} target="_blank">
                contact us
              </Link>
            </>
          }
        />
        {hasAddressData && !fetchingAddressData && (
          <Select
            name="address"
            label="Select an address"
            values={sortedAddressList}
            onChange={handleAddressChange}
          />
        )}
        {(isAddressDataError || addressData?.length === 0) && (
          <Alert
            icon={<IconWarning size={20} color={action.error} />}
            severity="error"
          >
            <Typography>
              No addresses found. Make sure you&apos;ve entered the postcode
              correctly & try again
            </Typography>
          </Alert>
        )}
      </Stack>
    </Card>
  );
};

export default AddressFinder;
