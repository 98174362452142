import { useState } from 'react';
import { useRouter } from 'next/router';
import { Typography, Card } from '@krakentech/coral';

import SummaryCard from '@/components/SummaryCard';
import TryAgainError from '@/components/TryAgainError';
import { PAYMENT_PLAN_BILLING_CONFIG } from '@/consts/directDebit';
import { PAYMENT_FREQUENCY_FIELD_OPTIONS } from '@/consts/paymentFrequency';
import { useDirectDebitContext } from '@/context';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import { SummaryCardItem } from '@/types/directDebit';
import { getPaymentScheduleSummaryPrefix } from '@/utils/directDebit';
import { formatCurrency } from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';
import { INTERNAL_PATHS } from '@/utils/urls';

const NewPaymentScheduleSummary = () => {
  const [hasRetriedActiveWaterMeterQuery, setHasRetriedActiveWaterMeterQuery] =
    useState(false);
  const { push } = useRouter();
  const {
    isFetching,
    isError,
    data: hasActiveWaterMeter,
    refetch,
  } = useHasActiveWaterMeter();
  const {
    paymentFrequency,
    paymentDay,
    paymentAmount,
    firstPossibleFixedSchedulePaymentDate,
    paymentPlanFirstInstalment,
    paymentPlanLastInstalment,
  } = useDirectDebitContext();

  if (isError) {
    return (
      <Card>
        <TryAgainError
          allowUserToRetryQuery={!hasRetriedActiveWaterMeterQuery}
          retryQuery={() => {
            refetch();
            setHasRetriedActiveWaterMeterQuery(true);
          }}
        >
          <Typography textAlign="center">
            Uh oh, looks like we can&apos;t display your recommended payment
            amount. Please try again or come back later
          </Typography>
        </TryAgainError>
      </Card>
    );
  }

  const billingConfig = process.env.NEXT_PUBLIC_PAYMENT_PLAN_BILLING_CONFIG;

  const atEndOfFinancialYear = paymentPlanLastInstalment?.paymentNumber === 1;

  const getPaymentPlanFirstInstalmentCardContent = () => {
    // Be glad we're not building this in a Slavic language, can you imagine the mess of this function trying to get the grammar correct (daily reminder if anyone ever asks you to help build Octopus Energy Poland, the answer is "no" and then you run very fast and very far away.)
    if (
      !paymentPlanFirstInstalment.amount ||
      !paymentPlanFirstInstalment.date
    ) {
      return '';
    }

    const paymentAmountToDisplay = formatCurrency(
      paymentPlanFirstInstalment.amount
    );
    const paymentDateToDisplay = formatFullDateShortMonth(
      paymentPlanFirstInstalment.date
    );
    const preposition =
      billingConfig === PAYMENT_PLAN_BILLING_CONFIG.FIRST_MORE ||
      atEndOfFinancialYear
        ? 'on'
        : 'from';

    return `${paymentAmountToDisplay} ${preposition} ${paymentDateToDisplay}`;
  };

  const getSummaryCardItems = () => {
    const summaryCardItems: Array<SummaryCardItem> = [
      {
        title: 'You pay:',
        content:
          paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.MONTHLY
            ? `Every month on the ${cardinalNumberToOrdinalNumber(paymentDay!)}`
            : 'When you get your bill',
        showChangeButton: true,
        onChangeButtonClick: () =>
          push(INTERNAL_PATHS.SET_UP_DIRECT_DEBIT[0].path),
      },
    ];

    // Cards to display if the user has an active water meter and wants to pay monthly
    if (
      hasActiveWaterMeter &&
      paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.MONTHLY
    ) {
      if (paymentAmount) {
        summaryCardItems.push({
          title: 'Payment amount:',
          content: formatCurrency(paymentAmount),
          showChangeButton: false,
        });
      }

      if (firstPossibleFixedSchedulePaymentDate) {
        summaryCardItems.push({
          title: 'Next payment:',
          content: formatFullDateShortMonth(
            firstPossibleFixedSchedulePaymentDate
          ),
          showChangeButton: false,
        });
      }
      // Cards to display if the user is unmetered and wants to pay monthly
    } else if (
      !hasActiveWaterMeter &&
      paymentFrequency !== PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE
    ) {
      if (
        paymentPlanFirstInstalment.amount &&
        paymentPlanFirstInstalment.date
      ) {
        summaryCardItems.push({
          title: getPaymentScheduleSummaryPrefix(
            billingConfig === PAYMENT_PLAN_BILLING_CONFIG.FIRST_MORE ||
              atEndOfFinancialYear
          ),
          content: getPaymentPlanFirstInstalmentCardContent(),
          showChangeButton: false,
        });
      }
      // Only display this card item if the data is available (obviously because Typescript innit) but also only if the last instalment's payment number is greater than 1. If it's 1, then it means there's only one payment to be made in this financial year.
      if (
        paymentPlanLastInstalment.amount &&
        paymentPlanLastInstalment.date &&
        !atEndOfFinancialYear
      ) {
        summaryCardItems.push({
          title: `${billingConfig === PAYMENT_PLAN_BILLING_CONFIG.FIRST_MORE ? 'Monthly payments:' : 'Final payment:'}`,
          content: `${formatCurrency(paymentPlanLastInstalment.amount)} ${billingConfig === PAYMENT_PLAN_BILLING_CONFIG.FIRST_MORE ? 'until' : 'on'} ${formatFullDateShortMonth(paymentPlanLastInstalment.date)}`,
          showChangeButton: false,
        });
      }
    }

    return summaryCardItems;
  };

  return <SummaryCard items={getSummaryCardItems()} isLoading={isFetching} />;
};

export default NewPaymentScheduleSummary;
