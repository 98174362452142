import Link from 'next/link';
import { Stack, Typography } from '@krakentech/coral';

import { INTERNAL_PATHS } from '@/utils/urls';

import Error from '../Error';
import FormContainer from '../forms/FormContainer';
import { useAuth } from '../Providers/Auth';

import { StyledBackLink } from './index.styled';

const NotFoundError = () => {
  const { isAuthenticated } = useAuth();

  return (
    <FormContainer>
      <Stack justifyContent="center">
        <Error
          errorTitle="404"
          subtitle="Page not found"
          explanation="The link you clicked may be broken or the page may have been removed."
        >
          <Link
            href={
              isAuthenticated
                ? INTERNAL_PATHS.HOME.path
                : INTERNAL_PATHS.LOGIN.path
            }
            passHref
          >
            <StyledBackLink>
              <Typography variant="h5" component="span">
                {isAuthenticated ? 'Go to dashboard' : 'Go to login'}
              </Typography>
            </StyledBackLink>
          </Link>
        </Error>
      </Stack>
    </FormContainer>
  );
};

export default NotFoundError;
