import { Stack } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { MutedText, FormSubmitButton } from '@/components';
import { useNonEmailSignUpContext } from '@/context';

const MobileNumberForm = ({
  setMobileNumberToSubmit,
  isLoading,
}: {
  setMobileNumberToSubmit: (mobileNumber: string) => void;
  isLoading: boolean;
}) => {
  const { setMobileNumber } = useNonEmailSignUpContext();

  const handleSubmit = (value: Record<string, string>) => {
    if (!value.mobileNumber) {
      return;
    }

    // Save value to context so it can be persisted later on
    setMobileNumber(value.mobileNumber);

    // Save value to local state so the lazy query can be automatically triggered. TODO: see if we can just use the context variable for this, or whether there will be some funny side effects if the user goes back a page (maybe we'll have some weirdness anyway, who knows)
    setMobileNumberToSubmit(value.mobileNumber);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        mobileNumber: '',
      }}
      validationSchema={Yup.object().shape({
        mobileNumber: Yup.string()
          .required('Please enter a mobile number')
          .phone('GB', 'Please enter a valid mobile number'),
      })}
    >
      <Form>
        <Stack direction="vertical" gap="lg">
          <Stack direction="vertical" gap="xs">
            <FormikTextField name="mobileNumber" label="Mobile number" />
            <MutedText>
              You may start with your international code or 0. For example,
              +447700 900 123 or 07700 900 123
            </MutedText>
          </Stack>
          <FormSubmitButton loading={isLoading}>Confirm</FormSubmitButton>
        </Stack>
      </Form>
    </Formik>
  );
};

export default MobileNumberForm;
