import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { useHasActiveDunningProcess } from '@/hooks/accounts/useHasActiveDunningProcess';
import { useToggle } from '@/hooks/utils/useToggle';
import { INTERNAL_PATHS } from '@/utils/urls';

const useDunningDialog = () => {
  const { replace } = useRouter();
  const [showDunningDialog, setShowDunningDialog] = useToggle(false);

  const {
    data: hasActiveDunning,
    isLoading,
    isError,
  } = useHasActiveDunningProcess();

  const isDunningLoading = useMemo(() => isLoading, [isLoading]);
  const isDunningError = useMemo(() => isError, [isError]);

  const redirectWithActiveDunning = () => {
    if (hasActiveDunning) {
      replace(INTERNAL_PATHS.HOME.path);
    }
  };

  return {
    showDunningDialog,
    setShowDunningDialog,
    isDunningLoading,
    isDunningError,
    hasActiveDunning,
    redirectWithActiveDunning,
  };
};

export default useDunningDialog;
