export const sortAddressList = (array: { value: string; label: string }[]) =>
  array.sort((a, b) => {
    const numA = parseInt(a.value, 10);
    const numB = parseInt(b.value, 10);

    const addressA = isNaN(numA) ? 0 : numA;
    const addressB = isNaN(numB) ? 0 : numB;

    return addressA - addressB;
  });
