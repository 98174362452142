import Script from 'next/script';
import { Typography, Stack, Link, Grid, Container } from '@krakentech/coral';
import { deleteCookie } from 'cookies-next';

import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { optOutOfAdobeAnalytics } from '@/lib/adobe';
import {
  setGoogleAnalyticsConsent,
  manuallyDeleteGoogleAnalyticsCookies,
} from '@/lib/gtag';
import { FeatureNames } from '@/types/features';
import { COOKIES } from '@/utils/cookies';
import { LOCAL_STORAGE_ITEMS } from '@/utils/localStorage';

import { StyledFooter, StyledFooterContent } from './index.styled';

const Footer = () => {
  const showAdobeAnalytics = useFeatureFlag(FeatureNames.AdobeAnalytics);
  const currentYear = new Date().getFullYear();
  const resetShownCookiePreferences = () => {
    // Delete cookie controlling the banner popping up
    deleteCookie(COOKIES.DISMISS_COOKIE_BANNER);
    // Manually delete GA cookies, which we have to do for some reason to stop them popping back up again
    manuallyDeleteGoogleAnalyticsCookies();
    // Delete cookiePreference from local storage
    localStorage.removeItem(LOCAL_STORAGE_ITEMS.COOKIE_PREFERENCE);
    // Set GA consent to false
    setGoogleAnalyticsConsent(false, false);
    // Switch off Adobe Analytics for clients that have AA enabled
    if (showAdobeAnalytics) {
      optOutOfAdobeAnalytics();
    }
    // TODO: delete AA cookie here too
    // Reload page to see changes
    window.location.reload();
  };

  return (
    <Container component={StyledFooter} theme="none">
      <Container
        theme="none"
        marginX="auto"
        maxWidth="lg"
        paddingX="lg"
        paddingTop="md"
        marginBottom="xxxl"
        paddingBottom="xl"
        md={{
          marginBottom: 'none',
          paddingBottom: 'md',
        }}
      >
        <Grid
          templateColumns="repeat(1, 1fr)"
          gap="md"
          lg={{ templateColumns: 'repeat(2, 1fr)' }}
        >
          <Stack
            direction="horizontal"
            justifyContent="center"
            gap="md"
            lg={{ justifyContent: 'start' }}
          >
            <Typography color="inherit" variant="body2">
              <Link
                href={process.env.NEXT_PUBLIC_PRIVACY_POLICY}
                target="_blank"
              >
                Privacy notice
              </Link>
            </Typography>
            <Typography color="inherit" variant="body2">
              <Link onClick={resetShownCookiePreferences}>
                Reset cookie settings
              </Link>
            </Typography>
          </Stack>
          <Stack
            justifyContent="center"
            lg={{
              justifyContent: 'end',
            }}
          >
            <StyledFooterContent>
              <Typography color="inherit" variant="body2">
                Copyright ©{' '}
                {currentYear !== 2023 ? `2023-${currentYear}` : '2023'}{' '}
                {process.env.NEXT_PUBLIC_COMPANY_NAME}
              </Typography>
            </StyledFooterContent>
          </Stack>
        </Grid>
      </Container>
      {showAdobeAnalytics && (
        <Script id="adobe-launch-footer-script">
          {`
                if(typeof _satellite!=="undefined"&&_satellite.pageBottom){_satellite.pageBottom()}else{console.log("Satellite not found, tracking could not work.")}
            `}
        </Script>
      )}
    </Container>
  );
};

export default Footer;
