import { Card, Stack, Typography } from '@krakentech/coral';

import { Body1Skeleton, H2Skeleton, PageError } from '@/components';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';

import NextAndFollowingPaymentsCards from '../NextAndFollowingPaymentsCards';

export const YourPaymentsCard = () => {
  const { isLoading, isError, data } = useCurrentActivePaymentSchedule();

  if (isLoading) return <LoadingState />;

  if (isError) return <PageError />;

  return (
    <Card>
      <Stack
        direction="vertical"
        gap="sm"
        md={{
          gap: 'md',
          direction: 'vertical',
        }}
      >
        <Typography variant="h2">Your payments</Typography>
        {data?.node?.isVariablePaymentAmount ? (
          <Typography>
            Please wait until your next bill to see your charges. A few days
            after this is issued, we&apos;ll collect your balance in full
          </Typography>
        ) : (
          <NextAndFollowingPaymentsCards
            currentActivePaymentScheduleData={data}
          />
        )}
      </Stack>
    </Card>
  );
};

const LoadingState = () => (
  <Card>
    <Stack
      direction="vertical"
      gap="md"
      md={{
        gap: 'sm',
        direction: 'vertical',
      }}
    >
      <H2Skeleton />
      <Body1Skeleton />
      <Body1Skeleton />
    </Stack>
  </Card>
);

export default YourPaymentsCard;
