import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Loader, Typography } from '@krakentech/coral';

import PageError from '@/components/PageError';
import { sessionAccountNumberKey } from '@/context/AccountNumberContext/withFetch/sessionKey';
import { useAccountNumberList } from '@/hooks/accounts/useAccountNumberList';
import { INTERNAL_PATHS } from '@/utils/urls';

export const AccountNumberContext = createContext<
  { accountNumber: string } | undefined
>(undefined);

export function AccountNumberContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const sessionAccountNumber =
    sessionStorage.getItem(sessionAccountNumberKey) ?? undefined;

  const [accountNumber, setAccountNumber] = useState<string | undefined>(
    sessionAccountNumber
  );

  const { data, isError, isSuccess, isLoading } = useAccountNumberList(
    accountNumber === undefined
  );

  useEffect(() => {
    if (accountNumber) {
      return;
    }

    if (isSuccess) {
      if (data && data.length === 1) {
        const accNumber = data[0].number;
        setAccountNumber(accNumber);
        sessionStorage.setItem(sessionAccountNumberKey, accNumber);
      }
    }
  }, [accountNumber, data, isSuccess]);

  if (accountNumber) {
    return (
      <AccountNumberContext.Provider value={{ accountNumber }}>
        {children}
      </AccountNumberContext.Provider>
    );
  }

  if (isError) {
    return <PageError />;
  }

  if (isLoading || !data) {
    return <Loader type="linear" variant="indeterminate" />;
  }

  if (data.length !== 1) {
    return <Typography>Multiple accounts</Typography>;
  }

  return null;
}

export function useAccountNumberContext(): { accountNumber: string } {
  const context = useContext(AccountNumberContext);

  if (context === undefined || context.accountNumber === undefined) {
    // This shouldn't really happen, unless the user is no longer logged in
    // (in which case they'll get redirected to the login anyway).
    // If this does happen we redirect the user to an error page.
    // TODO: Once it is set up we should report this to Sentry and investigate if/when this happens.
    window.location.href = INTERNAL_PATHS.ERROR.path;

    // Returning undefined is a Typescript violation but if the account number
    // is undefined we redirect to an error page where account number isn't
    // used so this is safe.
    // @ts-expect-error see above^^
    return;
  }

  return context as { accountNumber: string };
}
