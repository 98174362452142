import { useRouter } from 'next/router';
import { Stack, Card, Typography } from '@krakentech/coral';

import { Alert } from '@/components';
import ReviewDetail from '@/components/ReviewDetail';

type UserRegisterDetailsSummaryProps = {
  userDetails: { title: string; detail: string; changeDetailPath: string }[];
};

const UserRegisterDetailsSummary = ({
  userDetails,
}: UserRegisterDetailsSummaryProps) => {
  const { push } = useRouter();

  return (
    <Stack direction="vertical" gap="smMd">
      <Alert severity="info">
        By registering, we&apos;ll switch you to paperless meaning all your
        bills and messages will go straight to your email. You can change this
        setting in your online account
      </Alert>
      <Card>
        <Stack direction="vertical" gap="smMd">
          <Typography variant="h2">Your details</Typography>
          {userDetails.map((item) => (
            <ReviewDetail
              key={item.title}
              title={item.title}
              details={item.detail}
              handleOnClick={() => push(item.changeDetailPath)}
            />
          ))}
        </Stack>
      </Card>
    </Stack>
  );
};

export default UserRegisterDetailsSummary;
