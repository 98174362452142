import { Link, Stack, Typography } from '@krakentech/coral';
import { IconFile } from '@krakentech/icons';

import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useBills } from '@/hooks/billsAndPayments/useBills';
import { usePagination } from '@/hooks/utils/usePagination';
import { DesktopAndTabletOnlyDiv, MobileOnlyDiv } from '@/styles/Utils.styled';
import { formatCurrency } from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';
import { INTERNAL_PATHS } from '@/utils/urls';

import NoDataMessage from '../../NoDataMessage';
import PageError from '../../PageError';
import Paginator from '../../Paginator';
import { Body2Skeleton } from '../../Skeletons';
import Table from '../../Table';

const BillsList = () => {
  const { accountNumber } = useAccountNumberContext();

  const { data, isLoading, isError, hasNextPage, fetchNextPage } = useBills({
    accountNumber,
  });

  const { paginatedData, numberOfDisplayedItems, totalNumberOfItems } =
    usePagination(data);

  if (isLoading || typeof paginatedData === 'undefined') {
    return <LoadingState />;
  }

  if (isError) {
    return <PageError />;
  }

  const chargeAmount = (grossTotal: number) => formatCurrency(grossTotal);

  const billingPeriod = (fromDate: string, toDate: string) =>
    `${formatFullDateShortMonth(
      new Date(fromDate)
    )} - ${formatFullDateShortMonth(new Date(toDate))}`;

  return (
    <div data-testid="billsList">
      {paginatedData.length ? (
        <>
          <DesktopAndTabletOnlyDiv>
            <Table
              columns={[
                {
                  name: 'Date issued',
                  width: 10,
                },
                {
                  name: 'Billing period',
                  width: 50,
                },
                {
                  name: 'Amount',
                  width: 10,
                },
                {
                  name: '',
                  width: 30,
                },
              ]}
              data={paginatedData.map(
                ({
                  node: {
                    id,
                    issuedDate,
                    fromDate,
                    toDate,
                    billType,
                    temporaryUrl,
                    totalCharges: { grossTotal },
                  },
                }) => ({
                  issuedDate: issuedDate && (
                    <Typography textWrap="nowrap">
                      {formatFullDateShortMonth(new Date(issuedDate))}
                    </Typography>
                  ),
                  billingPeriod: fromDate && toDate && (
                    <Typography>{billingPeriod(fromDate, toDate)}</Typography>
                  ),
                  totalCharges: grossTotal && (
                    <Typography component="p" variant="h3">
                      {chargeAmount(grossTotal)}
                    </Typography>
                  ),
                  downloadLink: billType && id && temporaryUrl && (
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      gap="xxs"
                    >
                      <Link
                        href={INTERNAL_PATHS.SINGLE_BILL_DOWNLOAD.createPath({
                          billType,
                          id,
                        })}
                        target="_blank"
                      >
                        View PDF
                      </Link>
                    </Stack>
                  ),
                })
              )}
            />
          </DesktopAndTabletOnlyDiv>

          <MobileOnlyDiv>
            <Table
              columns={[
                {
                  name: '',
                  width: 90,
                },
                {
                  name: '',
                  width: 10,
                },
              ]}
              data={paginatedData.map(
                ({
                  node: {
                    issuedDate,
                    fromDate,
                    toDate,
                    billType,
                    id,
                    totalCharges: { grossTotal },
                  },
                }) => ({
                  left: (
                    <Stack direction="vertical">
                      {issuedDate && (
                        <Typography component="p" variant="h3">
                          {formatFullDateShortMonth(new Date(issuedDate))}
                        </Typography>
                      )}
                      {fromDate && toDate && (
                        <Typography
                          {...(grossTotal && {
                            variant: 'body2',
                          })}
                        >
                          {billingPeriod(fromDate, toDate)}
                        </Typography>
                      )}
                      <Stack alignItems="center" gap="xxs">
                        <IconFile size={16} />
                        <Link
                          href={INTERNAL_PATHS.SINGLE_BILL_DOWNLOAD.createPath({
                            billType,
                            id,
                          })}
                          target="_blank"
                        >
                          View PDF
                        </Link>
                      </Stack>
                    </Stack>
                  ),
                  right: grossTotal && (
                    <Typography component="p" variant="h3" textAlign="right">
                      {chargeAmount(grossTotal)}
                    </Typography>
                  ),
                })
              )}
            />
          </MobileOnlyDiv>

          <Paginator
            buttonLabel="Show more bills"
            totalNumberOfItems={totalNumberOfItems}
            numberOfItemsDisplayed={numberOfDisplayedItems}
            hasNextPage={hasNextPage}
            onButtonClick={fetchNextPage}
          />
        </>
      ) : (
        <NoDataMessage
          title="No bills available"
          body="Previous bills cannot be displayed - please come back when you receive a new bill"
        />
      )}
    </div>
  );
};

const LoadingState = () => (
  <div data-testid="paymentsList">
    <DesktopAndTabletOnlyDiv>
      <Table
        isLoading
        columns={[
          {
            name: '',
            width: 10,
          },
          {
            name: '',
            width: 60,
          },
          {
            name: '',
            width: 10,
          },
          {
            name: '',
            width: 10,
          },
        ]}
      />
    </DesktopAndTabletOnlyDiv>

    <MobileOnlyDiv>
      <Table
        columns={[
          {
            name: '',
            width: 75,
          },
          {
            name: '',
            width: 25,
          },
        ]}
        data={[
          {
            left: <Body2Skeleton />,
            right: <Body2Skeleton />,
          },
          {
            left: <Body2Skeleton />,
            right: <Body2Skeleton />,
          },
        ]}
      />
    </MobileOnlyDiv>
  </div>
);

export default BillsList;
