import { Card, Stack, Typography, Link } from '@krakentech/coral';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { FormTextFieldWithDescription } from '@/components';
import {
  Alert,
  FormSubmitButton,
  PasswordRequirementsList,
  FormPasswordField,
} from '@/components';
import MutedText from '@/components/MutedText';
import {
  MIN_CHARACTER_LENGTH,
  MIN_CHARACTER_LENGTH_ERROR_MESSAGE,
} from '@/consts/passwordValidation';
import {
  requiredEmailAddressValidationMessage,
  passwordConfirmDoesNotMatchValidationMessage,
  requiredNewPasswordConfirmValidationMessage,
  requiredNewPasswordValidationMessage,
  validEmailAddressValidationMessage,
} from '@/consts/validation';
import { IS_STW } from '@/utils/environment';
import { stwPasswordValidationSchema } from '@/utils/validators/yup';

export type RegisterFormSubmitValues = {
  email: string;
  newPassword: string;
};

type RegisterFormProps = {
  onSubmit: (values: RegisterFormSubmitValues) => void;
  error: boolean;
  header: string;
  description?: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(validEmailAddressValidationMessage)
    .required(requiredEmailAddressValidationMessage),
  newPassword: IS_STW
    ? stwPasswordValidationSchema
    : Yup.string()
        .min(MIN_CHARACTER_LENGTH, MIN_CHARACTER_LENGTH_ERROR_MESSAGE)
        .required(requiredNewPasswordValidationMessage),
  newPasswordConfirm: Yup.string()
    .oneOf(
      [Yup.ref('newPassword')],
      passwordConfirmDoesNotMatchValidationMessage
    )
    .required(requiredNewPasswordConfirmValidationMessage),
});

const RegisterForm = ({
  onSubmit,
  error,
  header,
  description,
}: RegisterFormProps) => {
  const routes = {
    termsAndConditions: process.env.NEXT_PUBLIC_TERMS_AND_CONDITIONS,
    privacyNotice: process.env.NEXT_PUBLIC_PRIVACY_POLICY,
  };

  return (
    <Formik
      initialValues={{
        email: '',
        newPassword: '',
        newPasswordConfirm: '',
      }}
      // Ensures `isValid` is false onLoad
      initialErrors={{
        email: 'required',
        newPassword: 'required',
        newPasswordConfirm: 'required',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid }) => (
        <Form>
          <Stack direction="vertical" gap="md">
            <Card>
              <Stack direction="vertical" gap="smMd">
                <Typography variant="h2">{header}</Typography>
                {description && <Typography>{description}</Typography>}

                <FormTextFieldWithDescription
                  fieldLabel="Email address"
                  fieldName="email"
                  description="Enter the email you want to use to log
                                        in with and receive any messages from us"
                />
                <FormPasswordField
                  name="newPassword"
                  id="newPassword"
                  label="New password"
                />
                <PasswordRequirementsList hasPassword={false} />
                <FormPasswordField
                  name="newPasswordConfirm"
                  id="newPasswordConfirm"
                  label="Confirm new password"
                />
              </Stack>
            </Card>

            {error && (
              <Alert severity="error">
                There was a problem trying to register your account. Please wait
                a few minutes and try again
              </Alert>
            )}

            <Stack direction="vertical">
              <Stack direction="vertical" gap="xxs">
                {/* Clients may have one or both of 'terms and conditions' and a 'privacy notice' */}
                {routes.termsAndConditions && (
                  <MutedText>
                    By completing registration, you agree to our{' '}
                    <Link href={routes.termsAndConditions} target="_blank">
                      terms and conditions
                    </Link>
                  </MutedText>
                )}
                {routes.privacyNotice && (
                  <MutedText>
                    For more information on how we process your personal data,
                    please see our{' '}
                    <Link href={routes.privacyNotice} target="_blank">
                      Privacy Notice
                    </Link>
                  </MutedText>
                )}
              </Stack>
              <FormSubmitButton loading={false} disabled={!isValid}>
                Confirm and Register
              </FormSubmitButton>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
