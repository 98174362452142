import { useRouter } from 'next/router';
import { SkipToContent } from '@krakentech/coral';

import { useAuth } from '@/components/Providers/Auth';
import { SkipToContentOverride } from '@/styles/Overrides.styled';


import MasqueradeBanner from '../../auth/MasqueradeBanner';
import CookiePreferences from '../../CookiePreferences';
import Footer from '../../Footer';
import GlobalMessage from '../../GlobalMessage';
import Header from '../../Header';

import { StyledPageLayout } from './index.styled';

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => {
  const { route } = useRouter();
  const { masqueradeToken } = useAuth();

  return (
    <>
      {/* Since we are routing any nonexisting pages to the custom 404.tsx page, 
                conditionally rendering the SkipToContent component avoids the following issue:
                Warning: Prop `href` did not match. Server: "/404#maincontent" Client: "/test#maincontent" */}
      {route !== '/404' && (
        <SkipToContentOverride>
          <SkipToContent id="maincontent" />
        </SkipToContentOverride>
      )}

      {masqueradeToken && <MasqueradeBanner />}

      <Header />
      <StyledPageLayout id="maincontent">
        <GlobalMessage />
        {children}
      </StyledPageLayout>
      <CookiePreferences />
      <Footer />
    </>
  );
};

export default PageLayout;
