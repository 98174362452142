import { useMemo } from 'react';
import { Grid } from '@krakentech/coral';
import { IconGBP } from '@krakentech/icons';

import { ActionCard } from '@/components';
import DunningDialog from '@/components/DunningDialog';
import { hasCurrentDirectDebitInstruction } from '@/fixtures/overview';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';
import { useHasCurrentDirectDebitInstruction } from '@/hooks/billsAndPayments/useHasCurrentDirectDebitInstruction';
import useDunningDialog from '@/hooks/utils/useDunningDialog';
import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { INTERNAL_PATHS } from '@/utils/urls';

const DirectDebitActionCard = () => {
  const {
    showDunningDialog,
    setShowDunningDialog,
    hasActiveDunning,
    isDunningLoading,
    isDunningError,
  } = useDunningDialog();

  const {
    isLoading: isDirectDebitInstructionLoading,
    isError: isDirectDebitInstructionError,
    data: hasDirectDebitInstruction,
  } = useHasCurrentDirectDebitInstruction();

  const { data: paymentSchedule } = useCurrentActivePaymentSchedule();
  const showDirectDebitV2 = useFeatureFlag(FeatureNames.DirectDebitVersion2);

  const cardTitle = hasDirectDebitInstruction
    ? 'Manage payments'
    : 'Set up a Direct Debit';

  const ddSetUpPage = showDirectDebitV2
    ? INTERNAL_PATHS.SET_UP_DIRECT_DEBIT[0]
    : INTERNAL_PATHS.SET_UP_DIRECT_DEBIT[1];

  const isLoading = useMemo(
    () => isDirectDebitInstructionLoading || isDunningLoading,
    [isDirectDebitInstructionLoading, isDunningLoading]
  );

  const isError = useMemo(
    () => isDirectDebitInstructionError || isDunningError,
    [isDirectDebitInstructionError, isDunningError]
  );

  const hasDDIButNoActivePaymentSchedule =
    hasCurrentDirectDebitInstruction && typeof paymentSchedule === 'undefined';

  const getActionCardHref = () => {
    if (hasDirectDebitInstruction) {
      return INTERNAL_PATHS.MANAGE_PAYMENTS.path;
    } else {
      return ddSetUpPage.path;
    }
  };

  if (hasDDIButNoActivePaymentSchedule) {
    return null;
  }

  return (
    <>
      <Grid.Item>
        <ActionCard
          {...(!hasActiveDunning &&
            showDirectDebitV2 && { href: getActionCardHref() })}
          title={cardTitle}
          titleComponent="p"
          icon={<IconGBP size={32} />}
          onClick={setShowDunningDialog}
          isLoading={isLoading}
          isError={isError}
        />
      </Grid.Item>
      <DunningDialog
        isOpen={showDunningDialog}
        closeDialog={setShowDunningDialog}
        ariaLabelAction="setupDirectDebit"
      />
    </>
  );
};

export default DirectDebitActionCard;
