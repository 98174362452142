import styled, { css } from 'styled-components';

// To be removed when the `overLightBackground` prop works on the `Select` component, when the text in the dropdown itself is no longer white
export const SelectOverride = styled.div`
  ${({ theme }) => css`
    label {
      color: ${theme.color.text.main};
    }
    div[role='option'] {
      &:hover {
        color: ${theme.color.base.main};
      }
    }
    div[aria-selected='true'] {
      color: ${theme.color.base.main};
    }
  `}
`;
