import { useState } from 'react';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import {
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';
import { getCookie, setCookie } from 'cookies-next';

import DunningDialog from '@/components/DunningDialog';
import { usePersonalDetails } from '@/hooks/accounts/usePersonalDetails';
import useDunningDialog from '@/hooks/utils/useDunningDialog';
import { COOKIES } from '@/utils/cookies';
import { INTERNAL_PATHS } from '@/utils/urls';
import { isFieldEmpty } from '@/utils/validators/general';

import { CircleIcon, PageError } from '../..';

const AccountEnrichmentCard = () => {
  const [displayComponent, setDisplayComponent] = useState(true);

  const {
    showDunningDialog,
    setShowDunningDialog,
    hasActiveDunning,
    isDunningLoading,
    isDunningError,
  } = useDunningDialog();

  const { data, isLoading, isError } = usePersonalDetails();

  const { isMinMd } = useCoralBreakpoints();

  if (isLoading || isDunningLoading) {
    return null;
  }

  if (isError || isDunningError) {
    return <PageError />;
  }

  const { viewer } = data;

  const isGivenNameEmpty = isFieldEmpty(viewer.givenName);
  const isFamilyNameEmpty = isFieldEmpty(viewer.familyName);
  const isGivenNameASingleCharacter = viewer.givenName.length === 1;

  const isDateOfBirthEmpty = isFieldEmpty(viewer.dateOfBirth);
  const isContactNumberEmpty =
    isFieldEmpty(viewer.mobile) && isFieldEmpty(viewer.landline);

  if (
    !isGivenNameEmpty &&
    !isFamilyNameEmpty &&
    !isGivenNameASingleCharacter &&
    !isDateOfBirthEmpty &&
    !isContactNumberEmpty
  ) {
    return null;
  }

  const missingFields = [];

  const missingNameFields = [
    {
      condition:
        (isGivenNameEmpty || isGivenNameASingleCharacter) && isFamilyNameEmpty,
      message: 'full first and last name',
    },
    {
      condition: isGivenNameEmpty,
      message: 'first name',
    },
    {
      condition: isGivenNameASingleCharacter,
      message: 'full first name',
    },
    {
      condition: isFamilyNameEmpty,
      message: 'last name',
    },
  ];

  // Add a different missing name field depending on what part of or if all of the name is missing
  for (const { condition, message } of missingNameFields) {
    if (condition) {
      missingFields.push(message);
      break;
    }
  }

  if (isDateOfBirthEmpty) {
    missingFields.push('date of birth');
  }

  if (isContactNumberEmpty) {
    missingFields.push('contact number');
  }

  const formatMissingFields = (input: string[]) => {
    const numberOfFields = input.length;

    if (numberOfFields === 0) {
      return '';
    } else if (numberOfFields === 1) {
      return input[0];
    } else if (numberOfFields === 2) {
      return input.join(' & ');
    } else {
      const firstPart = input.slice(0, numberOfFields - 2).join(', ');
      const lastPart = input.slice(numberOfFields - 2).join(' & ');
      return `${firstPart}, ${lastPart}`;
    }
  };

  const missingFieldsText = formatMissingFields(missingFields);

  const { SNOOZE_ACCOUNT_ENRICHMENT } = COOKIES;

  const handleClose = () => {
    setCookie(SNOOZE_ACCOUNT_ENRICHMENT, true);
    setDisplayComponent(false);
  };

  if (!displayComponent || getCookie(SNOOZE_ACCOUNT_ENRICHMENT)) return null;

  const personalDetailsHref = INTERNAL_PATHS.UPDATE_PERSONAL_DETAILS.path;

  const personalDetailsButtonAction = hasActiveDunning
    ? {
        onClick: () => setShowDunningDialog(),
      }
    : {
        href: personalDetailsHref,
      };

  return (
    <>
      <Card
        padding="small"
        md={{
          padding: 'medium',
        }}
      >
        <Stack gap="sm" direction="vertical">
          <Grid
            templateColumns="repeat(1, 1fr)"
            alignItems="center"
            gap="sm"
            lg={{
              templateColumns: 'repeat(2, 1fr)',
              gap: 'md',
            }}
          >
            <Stack
              alignItems="center"
              direction="vertical"
              lg={{ direction: 'horizontal' }}
            >
              <CircleIcon icon={faUser} />
              <Stack
                alignItems="center"
                direction="vertical"
                gap="none"
                lg={{
                  direction: 'vertical',
                  alignItems: 'normal',
                  gap: 'none',
                }}
              >
                <Typography variant="h2">
                  Let&apos;s complete your profile!
                </Typography>
                <Typography
                  variant="body2"
                  {...(!isMinMd && { textAlign: 'center' })}
                >
                  Add your {missingFieldsText}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              justifyContent="center"
              gap="sm"
              direction="vertical"
              lg={{
                justifyContent: 'flex-end',
              }}
              md={{
                direction: 'horizontal',
                gap: 'md',
              }}
            >
              <Button {...personalDetailsButtonAction}>Add information</Button>
              <Button variant="outlined" onClick={handleClose}>
                Not now
              </Button>
            </Stack>
          </Grid>
        </Stack>
      </Card>
      <DunningDialog
        isOpen={showDunningDialog}
        closeDialog={setShowDunningDialog}
        ariaLabelAction="addMorePersonalInformation"
      />
    </>
  );
};

export default AccountEnrichmentCard;
