import { Stack } from '@krakentech/coral';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { DateField, FormSubmitButton } from '@/components';
import { useNonEmailSignUpContext } from '@/context';
import { SeparatedDate } from '@/types/date';
import { makeDateKrakenFriendly } from '@/utils/formatters/date';
import { validateDateOfBirth as dateOfBirth } from '@/utils/validators/yup';

const DateOfBirthForm = ({
  setDateOfBirthToSubmit,
  isLoading,
}: {
  setDateOfBirthToSubmit: (dateOfBirth: string) => void;
  isLoading: boolean;
}) => {
  const { setDateOfBirth } = useNonEmailSignUpContext();

  const handleSubmit = (value: { dateOfBirth: SeparatedDate }) => {
    if (!value.dateOfBirth.year) {
      return;
    }

    const krakenFriendlyDateOfBirth = makeDateKrakenFriendly(
      new Date(
        `${value.dateOfBirth.year}-${value.dateOfBirth.month}-${value.dateOfBirth.day}`
      )
    );

    // Save value to context so it can be persisted later on
    setDateOfBirth(krakenFriendlyDateOfBirth);

    // Save value to local state so the lazy query can be automatically triggered. TODO: see if we can just use the context variable for this, or whether there will be some funny side effects if the user goes back a page (maybe we'll have some weirdness anyway, who knows)
    setDateOfBirthToSubmit(krakenFriendlyDateOfBirth);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        dateOfBirth: {
          day: '',
          month: '',
          year: '',
        },
      }}
      validationSchema={Yup.object().shape({
        dateOfBirth,
      })}
    >
      <Form>
        <Stack direction="vertical" gap="lg">
          <DateField fieldArrayName="dateOfBirth" title="" />
          <FormSubmitButton loading={isLoading}>Confirm</FormSubmitButton>
        </Stack>
      </Form>
    </Formik>
  );
};

export default DateOfBirthForm;
