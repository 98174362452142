import { Stack, Card, Typography, Link } from '@krakentech/coral';
import { Formik, Form } from 'formik';

import {
  ErrorLink,
  FormContainer,
  FormSubmitButton,
  MutedText,
  Select,
  Alert,
  UnorderedList,
} from '@/components';
import { INTERNAL_PATHS } from '@/utils/urls';

import { SelectUserFormProps } from './index.types';

const SelectUserForm = ({
  defaultSelectedOption,
  selectOptions,
  onSubmit,
  error = false,
  onChange,
  disabled,
  showNotEnoughInfoAlert,
  email,
}: SelectUserFormProps) => {
  const { givenName, familyName } = defaultSelectedOption || {};

  return (
    <Formik
      initialValues={{
        userName:
          familyName && givenName
            ? {
                label: `${givenName} ${familyName}`,
                value: `${givenName}_${familyName}`,
              }
            : selectOptions[0],
      }}
      onSubmit={onSubmit}
    >
      <Form>
        <FormContainer>
          <Stack gap="lg" direction="vertical">
            <Card>
              <Stack direction="vertical" gap="smMd">
                <Typography variant="h2">Who are you?</Typography>
                <Select
                  onChange={onChange}
                  name="userName"
                  id="userName"
                  label="Select your name"
                  values={selectOptions}
                />
                <MutedText>
                  If you can&apos;t see your name listed,{' '}
                  <Link
                    target="_blank"
                    href={process.env.NEXT_PUBLIC_HELP_LINK}
                  >
                    get in touch
                  </Link>
                </MutedText>
              </Stack>
            </Card>
            {error && (
              <Alert severity="error">
                <Typography>
                  Sorry, you can&apos;t complete this online right now - please{' '}
                  <ErrorLink href={process.env.NEXT_PUBLIC_HELP_LINK || ''}>
                    get in touch
                  </ErrorLink>{' '}
                  and our team will help you.
                </Typography>
              </Alert>
            )}

            {showNotEnoughInfoAlert && (
              <Alert severity="error">
                <Typography>
                  Sorry, we don&apos;t have enough information on your account
                  to register online - please{' '}
                  <ErrorLink href={process.env.NEXT_PUBLIC_HELP_LINK || ''}>
                    get in touch
                  </ErrorLink>{' '}
                  and our team will help you get access to your online account.
                </Typography>
              </Alert>
            )}
            {email && (
              <Alert severity="warning">
                <Stack gap="sm" direction="vertical">
                  <Typography>
                    This account is already registered under {email}. What would
                    you like to do:
                  </Typography>
                  <UnorderedList
                    gap="xs"
                    listItems={[
                      <Link href={INTERNAL_PATHS.LOGIN.path} key="Log in">
                        Log in
                      </Link>,
                      <Link
                        href={INTERNAL_PATHS.FORGOTTEN_PASSWORD.path}
                        key="Reset password"
                      >
                        Reset password
                      </Link>,
                    ]}
                  />
                </Stack>
              </Alert>
            )}
            <FormSubmitButton
              disabled={disabled || showNotEnoughInfoAlert || !!email}
            >
              Continue
            </FormSubmitButton>
          </Stack>
        </FormContainer>
      </Form>
    </Formik>
  );
};

export default SelectUserForm;
