import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { useAnalytics } from '@/hooks/utils/useAnalytics';

import { useAuth } from '../Providers/Auth';

// TODO: do something with this once we're confident we have a working set up. Right now this only houses GA and some Analytics functions and so it seems a bit silly to live in this state whilst the Adobe Analytics tag has to be elsewhere.
// The GA stuff could be either moved to the _document.page.tsx file, or we could move both GA and Adobe into a file that's linked there (and then silence the Next warnings about beforeInteractive being used outside the Head). This component can't be referenced there because it would be run before router is available and honestly just cause a load of havoc and mess.
const Analytics = () => {
  const router = useRouter();
  const {
    trackPageView,
    adobe: { enabled: adobeAnalyticsEnabled, ready: adobeAnalyticsReady },
  } = useAnalytics();
  const { hasLoaded } = useAuth();

  /**
   * Handle route / hash changes in the browser
   * and log those in Google/Adobe Analytics.
   */
  useEffect(() => {
    const handleRouteChange = () => {
      trackPageView();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events, trackPageView]);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof document !== 'undefined' &&
      hasLoaded
    ) {
      trackPageView();
    }
  }, [adobeAnalyticsEnabled, adobeAnalyticsReady, hasLoaded, trackPageView]);

  return (
    <>
      {/* Google Tag Manager */}
      <Script id="ga-data-layer-inline">
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              // set initial consent values to 'denied'
              gtag('consent', 'default', {
                'ad_storage': 'denied',
                'analytics_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
              });
            `}
      </Script>

      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_ID}`}
      />

      <Script id="ga-data-layer-inline">
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${process.env.NEXT_PUBLIC_GA_TRACKING_ID}');
            `}
      </Script>

      <Script id="gtm-inline">
        {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTAG_ID}');
            `}
      </Script>
    </>
  );
};

export default Analytics;
